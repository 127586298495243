import React, { useState } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { createBooking } from '../api';

import BookingForm from './BookingForm'
import ProductInfo from './ProductInfo'
import Loader from 'react-loader-spinner';

export default function BookingPage({ orderId, voucherKey, voucherSecret, sku, tourGrade, travellers, product, debug }) {

  const [isCreatingBooking, setIsCreatingBooking] = useState(false);
  const [error, setError] = useState(false);

  const doBooking = data => {
    setIsCreatingBooking(true);
    _doBooking();

    async function _doBooking() {

      try {
        const response = await createBooking({
          voucherKey,
          voucherSecret,
          bookingRequest: data
        });

        if (response.error) {
          setError(response.error);
        } else {
          window.location.href = response.data.voucherURL;
        }


      } catch( error ) {
        setError("We are sorry - an error has occurred when trying to process your booking. Please try again later.");
        console.error("BOOKING ERROR:", error);
      }

    }

  };

  return (
    <>
        {
          (error && <div>
            <div className="alert alert-danger" role="alert">
                {typeof error === 'string' ? error : error.error}
            </div>
          </div>)
          ||
          (isCreatingBooking && <div className="text-center"><Loader
                                        type="Puff"
                                        color="#FFB99B"
                                        height={100}
                                        width={100}
                                        style={{ textAlign: 'center'}}
                                    />
                                    Creating your booking...
        </div>)
          ||
          <>
            <Row>
              <Col sm={{ span: 6 }} >
                <ProductInfo product={product} debug={debug} />
              </Col>
              <Col sm={{ span: 6}}>
                <BookingForm
                  orderId={orderId}
                  sku={sku}
                  tourGrade={tourGrade}
                  travellers={travellers}
                  createBooking={doBooking}
                  product={product}
                  debug={debug}
                  />
              </Col>
            </Row>
          </>
       }
    </>
  );
}
