import React, { useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import OrderBookingPage from './OrderBookingPage';

export default function DevOrderBookingPage() {
  const { register, watch } = useForm();
  const [orderId, setOrderId] = useState('N11975');
  const [voucherKey, setVoucherKey] = useState('0000000537383');
  const [voucherSecret, setVoucherSecret] = useState('RCI');

  const debug = watch('debug', false);
  const setOrderData = (orderId, voucherKey, voucherSecret) => {
    setOrderId(orderId);
    setVoucherKey(voucherKey);
    setVoucherSecret(voucherSecret);
  }

  return <>
  <Container fluid>
      <Row style={{ backgroundColor: '#F8F8F8', borderBottom: '1px solid #D1D1D1', paddingTop: 10, marginBottom: 50 }}>
        <Col>
          <Form.Group controlId="tourData">
            <Form.Label hidden>Order data:</Form.Label>
            Order ID: {orderId}, Voucher Key: {voucherKey}, Voucher Secret: {voucherSecret}
            <p>
              <span className="a" onClick={_ => setOrderData('N11959', '3000000539224', 'rSp')}>N11959</span>
              | <span className="a" onClick={_ => setOrderData('N11975', '0000000537383', 'RCI')}>N11975</span>
              | <span className="a" onClick={_ => setOrderData('N11975', '0000000537380', 'RCI')}>N11975 (incorrect voucher)</span>
            </p>
          </Form.Group>
        </Col>

        <Col>
          <Form.Group controlId="debug">
            <Form.Check type="checkbox" name="debug" ref={register()} label="Debug" />
          </Form.Group>
        </Col>
      </Row>
    </Container>

    <OrderBookingPage
      orderId={orderId}
      voucherKey={voucherKey}
      voucherSecret={voucherSecret}
      debug={debug}
      />

  </>
}