import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { getToursByVoucher } from '../api';

import Loader from 'react-loader-spinner';
import BookingPage from './BookingPage';

import ProductSelector from './ProductSelector';
import ProductInfo from './ProductInfo';
import { Image } from 'react-bootstrap';

export default function OrderBookingPage({ voucherKey, voucherSecret, debug }) {

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  // From order:
  const [orderId, setOrderId] = useState();
  const [tourDatas, setTourDatas] = useState();
  const [selectedTour, setSelectedTour] = useState(false);

  useEffect(() => {
    getData();

    async function getData() {

      setTourDatas(false);
      setSelectedTour(false);
      setIsLoaded(false);
      setError(false);

      if ( !voucherKey || !voucherSecret ) {
        window.location = 'https://www.giftsaustralia.com.au/';
        setError('404 - Not Found');
        return;
      }

      try {

        const { orderId, data } = await getToursByVoucher({ voucherKey, voucherSecret});
        setOrderId(orderId);

        setTourDatas(data);

        // Show product selector, or go straight to the one tour?
        if (data.length === 1) {
          setSelectedTour(0);
        }

        setIsLoaded(true);

      } catch(error) {
        setIsLoaded(true);
        setError(error);

      }
    }
  }, [ voucherKey, voucherSecret ]);

  return (
    <>
    <Container>

      <Row>
        <Col col={12} className="mb-4">
          <Image fluid src="https://www.giftsaustralia.com.au/assets/banners/1042.jpg" alt="Gifts Australia" />
        </Col>
      </Row>
    </Container>

    <Container>
      { (error && <div>
        <div className="alert alert-danger" role="alert">
            {typeof error === 'string' ? error : error.error}
        </div>
      </div>)
        || ( !isLoaded && <Loader
                type="Puff"
                color="#FFB99B"
                height={100}
                width={100}
                style={{ textAlign: 'center'}}
            />)
        || (selectedTour === false && <>
          <ProductSelector products={tourDatas} setTour={setSelectedTour}/>
        </>)
        || (tourDatas[selectedTour].booking && <>
                                              <Row  className="justify-content-md-center">
                                                <Col md={8}>
                                                  <span className="a"  onClick={_ => setSelectedTour(false)}>&lt; Back</span>
                                                  <ProductInfo
                                                    product={tourDatas[selectedTour].product}
                                                    expanded={true}
                                                    />
                                                </Col>
                                              </Row>
                                              </>)
        || <>
          {tourDatas.length > 1 &&
                  <span className='a' onClick={_ => setSelectedTour(false)}>&lt; Back</span>
          }
          <BookingPage
            orderId={orderId}
            voucherKey={voucherKey}
            voucherSecret={voucherSecret}
            sku={tourDatas[selectedTour].sku}
            tourGrade={tourDatas[selectedTour].tourGrade}
            travellers={tourDatas[selectedTour].travellers}
            product={tourDatas[selectedTour].product}
            debug={debug}
            />
        </>
      }
    </Container>
    </>
  );
}