import { format } from "date-fns";

// cachedFetch, from https://www.sitepoint.com/cache-fetched-ajax-requests/
const hashstr = s => {
  let hash = 0;
  if (s.length === 0) return hash;
  for (let i = 0; i < s.length; i++) {
    let char = s.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

const cachedFetch = (url, options) => {
  let expiry = 5 * 60 // 5 min default
  if (typeof options === 'number') {
    expiry = options
    options = undefined
  } else if (typeof options === 'object') {
    // I hope you didn't set it to 0 seconds
    expiry = options.seconds || expiry
  }
  // Use the URL as the cache key to sessionStorage
  let cacheKey = hashstr(url)
  let cached = localStorage.getItem(cacheKey)
  let whenCached = localStorage.getItem(cacheKey + ':ts')
  if (cached !== null && whenCached !== null) {
    // it was in sessionStorage! Yay!
    // Even though 'whenCached' is a string, this operation
    // works because the minus sign tries to convert the
    // string to an integer and it will work.
    let age = (Date.now() - whenCached) / 1000
    if (age < expiry) {
      let response = new Response(new Blob([cached]))
      console.log('cached result', url, response);
      return Promise.resolve(response)
    } else {
      // We need to clean up this old key
      localStorage.removeItem(cacheKey)
      localStorage.removeItem(cacheKey + ':ts')
    }
  }

  return fetch(url, options).then(response => {
    // let's only store in cache if the content-type is
    // JSON or something non-binary
    if (response.status === 200) {
      let ct = response.headers.get('Content-Type')
      if (ct && (ct.match(/application\/json/i) || ct.match(/text\//i))) {
        // There is a .json() instead of .text() but
        // we're going to store it in sessionStorage as
        // string anyway.
        // If we don't clone the response, it will be
        // consumed by the time it's returned. This
        // way we're being un-intrusive.
        response.clone().text().then(content => {
          localStorage.setItem(cacheKey, content)
          localStorage.setItem(cacheKey + ':ts', Date.now())
        })
      }
    }
    return response
  })
}

const _handleError = res => {
  if (!res.data) {
    throw res;
  }
  return res;
};

function _get(endpoint) {
  return fetch('/api/' + endpoint, {
    seconds: 6000
  }).then(res => res.json()).then(_handleError);
}

function _post(endpoint, data) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data)
  };
  return fetch('/api/' + endpoint, requestOptions).then(res => res.json()).then(_handleError);;
}

export function getAvailability(sku) {
  return _get("availability?sku=" + sku);
}
export function getHotels(sku) {
  return _get("hotels/?sku=" + sku);
}

export function getTourgradeAvailability(sku, startDate, travellers) {
  return _post("tourgrade-availability", {
    sku,
    startDate: format(startDate, 'yyy-MM-dd'),
    travellers
   });
}

export function getTourgradeMatrix(sku, year, month) {
  return _post('tourgrade-matrix', {
    sku, year, month
  });
}

export async function getTourgradeMonthAvailability(sku, year, month, tourgrades) {
  try {
    const result = await getTourgradeMatrix(sku, year, month);
    return result.data.dates.filter(_ =>
      _.tourGrades.filter(
        _ => !tourgrades || tourgrades.includes(_.gradeCode)
      ).length
    ).map(_ => _.bookingDate);
  } catch {
    return [];
  }
}

export function getTourData(sku) {
  return _get("tour-data?sku=" + sku);
}

export function getTourDataByOrder({ orderId, sku, tourGrade }) {
  return _get(`neto-order?orderId=${orderId}&sku=${sku}&tourGrade=${tourGrade}`);
}

export function getToursByOrder({orderId, voucherKey, voucherSecret}) {
  return _get(`neto-order?orderId=${orderId}&voucherKey=${voucherKey}&voucherSecret=${voucherSecret}`);
}
export function getToursByVoucher({voucherKey, voucherSecret}) {
  return _get(`get-voucher?voucherKey=${voucherKey}&voucherSecret=${voucherSecret}`);
}

export async function createBooking({voucherKey, voucherSecret, bookingRequest}) {
  return _post("create-booking", {voucherKey, voucherSecret, bookingRequest});
}