
import React, { useState } from 'react';
import { BsCheck, BsX, BsDot, BsGeoAlt } from "react-icons/bs";
import { Button, Card } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';

import "react-image-gallery/styles/scss/image-gallery.scss";

export default function ProductInfo({ product, debug, expanded: alwaysExpanded }) {
  const [ isExpanded, setIsExpanded ] = useState(alwaysExpanded);

  const atts = {
    'Departure Time': product.departureTime,
    'Departure Point': product.departurePoint,
    'Return Details': product.returnDetails,
    'Duration': product.duration,
    'What\'s Included': <ul style={{ listStyle: 'none', padding: 0 }}>
      {product.inclusions && product.inclusions.filter(_ => _).map((v, i) => <li key={"inclusion-" + i}><BsCheck color='green' /> {v}</li>)}
      {product.exclusions && product.exclusions.filter(_ => _).map((v, i) => <li key={"exclusion-" + i}><BsX color='red' /> {v}</li>)}
    </ul>,
    'Supplier Name': product.supplierName
  };

  if (isExpanded) {
    atts['Additional Info'] = <ul style={{ listStyle: 'none', padding: 0 }}>
      {product.additionalInfo && product.additionalInfo.map((v, i) => <li key={"ai-" + i}><BsDot /> {v}</li>)}
    </ul>;
    atts['Itinerary'] = product.description;
  }

  // Add the expansion toggle
  if ( !alwaysExpanded) {
    atts[''] = isExpanded ? <Button onClick={_ => setIsExpanded(false)}>Hide Itinerary & Additional Info</Button> : <Button onClick={_ => setIsExpanded(true)}>View Itinerary & Additional Info</Button>;
  }


  return (
    <Card style={{
      boxShadow: '0 0 30px 0 rgba(0,0,0,.05)',
      border: '1px solid #ccc',
    }}>

      <Card.Img variant="top" src={product.thumbnailHiResURL} />
      <Card.Body>
        <Card.Title>{product.title}</Card.Title>
        <p><BsGeoAlt /> {product.location}</p>

        <hr />
        <p dangerouslySetInnerHTML={{ __html: product.shortDescription }} />

        <ImageGallery
          items={product.productPhotos.map(_ => ({
            original: _.photoURL,
            thumbnail: _.photoURL,
            description: _.caption
          }))}
          lazyLoad={true}
          showNav={false}
          showPlayButton={false}
          autoPlay={true}
          slideInterval={5000}
          />

        <table className="table">
          <tbody>
            {Object.entries(atts).filter(([k, v]) => v).map(([k, v]) =>
              <tr key={k}>
                <th style={{ whiteSpace: 'nowrap'}}>{k}</th>
                <td>{typeof v === 'string' ? <div dangerouslySetInnerHTML={{__html: v }} /> : v}</td>
              </tr>
            )}
          </tbody>
        </table>

        { debug && <pre>{JSON.stringify(product, false, '\t')}</pre> }
      </Card.Body>
    </Card>
  )
};