import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { useForm } from "react-hook-form";

import { getTourData } from '../api';

import Loader from 'react-loader-spinner';
import BookingPage from './BookingPage';
import { getTourGradeCode } from '../util';


export default function ProductBookingPage() {

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [product, setProduct] = useState(false);

  const { register, watch, setValue } = useForm();

  const sku = watch('sku', '100009P2');
  const tourGrade = watch( 'tour_grade' );


  const travellers = [
    0,
    parseInt(watch('num_travellers_1'))||0,
    parseInt(watch('num_travellers_2'))||0,
    parseInt(watch('num_travellers_3'))||0,
    parseInt(watch('num_travellers_4'))||0,
    parseInt(watch('num_travellers_5'))||0,
  ];

  useEffect(() => {
    getData();

    async function getData() {
      setIsLoaded(false);
      setError(false);
      setProduct(false);

      try {
        const result = await getTourData(sku);
        setProduct(result.data);
        setValue('tourGrade', result.data.tourGrades ? getTourGradeCode(result.data.tourGrades[0]) : 'DEFAULT');

        setIsLoaded(true);

      } catch(error) {
        setIsLoaded(true);
        setError(error);

      }
    }
  }, [sku, setValue]);

  const debug = watch('debug', false);

  const tourGradeIndexes = product.tourGrades && product.tourGrades.map(getTourGradeCode);
  const tourGrades = product.tourGrades && product.tourGrades.filter((_, index) => tourGradeIndexes.indexOf(getTourGradeCode(_)) === index);

  return (
    <>
    <Container fluid>
      <Row style={{ backgroundColor: '#F8F8F8', borderBottom: '1px solid #D1D1D1', paddingTop: 10, marginBottom: 50 }}>
        <Col>
          <Form.Group controlId="sku">
            <Form.Label hidden>Search by SKU:</Form.Label>
            <Form.Control type="text" name='sku' defaultValue='100009P2' ref={register()}  />
            <p>
              <span className="a" href='#' onClick={_ => setValue('sku', '5116_HR001')}>5116_HR001</span>
              &nbsp;| <span className="a" href='#' onClick={_ => setValue('sku', '100009P2')}>100009P2</span>
              &nbsp;| <span className="a" href='#' onClick={_ => setValue('sku', '5010SYDNEY')}>5010SYDNEY</span>
              &nbsp;| <span className="a" href='#' onClick={_ => setValue('sku', '3283BWW')}>3283BWW</span>
              &nbsp;| <span className="a" href='#' onClick={_ => setValue('sku', '5052DINNER')}>5052DINNER</span>
            </p>
          </Form.Group>
        </Col>
        {product && product.ageBands && product.ageBands.map(ageBand => <Col key={'ageband-' + ageBand.bandId}>
            <Form.Group controlId={"num_travellers_" + ageBand.bandId}>
              <Form.Control type="number" name={"num_travellers_" + ageBand.bandId} defaultValue={ageBand.bandId === 1 ? 1 : 0} ref={register()}  />
              <Form.Label>Number of {ageBand.pluralDescription}</Form.Label>
            </Form.Group>
        </Col>
        )}
        {product && !product.ageBands &&
          <Col>
            <Form.Group controlId="num_travellers_1">
              <Form.Control type="number" name='num_travellers_1' defaultValue='1' ref={register()}  />
              <Form.Label>Number of Adults</Form.Label>
            </Form.Group>
          </Col>
        }

        { product && product.tourGradesAvailable && tourGrades && <>
          <Col>
            <Form.Group controlId="tour_grade">
              <Form.Control as="select" name='tour_grade' ref={register()}>
                {tourGrades.map(_ => <option key={getTourGradeCode(_)} value={getTourGradeCode(_)}>{_.gradeTitle} ({_.gradeCode} {_.priceFromFormatted})</option>)}
              </Form.Control>
              <Form.Label>Tourgrade</Form.Label>
            </Form.Group>
          </Col>
        </>}

        <Col>
          <Form.Group controlId="debug">
            <Form.Check type="checkbox" name="debug" ref={register()} label="Debug" />
          </Form.Group>
        </Col>
      </Row>
    </Container>

    <Container>
      { sku ? <>
        { (error && <div>Error: <pre>{JSON.stringify(error, false, "\t")}</pre></div>)
          || (!isLoaded &&  <Loader
                  type="Puff"
                  color="#FFB99B"
                  height={100}
                  width={100}
                  style={{ textAlign: 'center'}}
              />)
          || (product  && <>
            <BookingPage
              sku={sku}
              tourGrade={tourGrade}
              travellers={travellers}
              product={product}
              debug={debug}
             />
          </>)
       }
      </> : <div>Please enter a SKU</div> }
    </Container>
    </>
  );
}
