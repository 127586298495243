import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker';

import { getTourgradeMonthAvailability, } from '../api';

import "react-datepicker/dist/react-datepicker.css";
import Loader from 'react-loader-spinner';


export default function ProductDatePicker({ sku, tourgrades, onChange, value, isInvalid }) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [availability, setAvailability] = useState([]);
  const [month, setMonth] = useState((new Date()).getMonth()+1);
  const [year, setYear] = useState((new Date()).getFullYear());

  const tourgradesJSON = JSON.stringify(tourgrades);
  useEffect(() => {
    if ( !sku || !year || !month ) {
      return;
    }

    setIsLoaded(false);
    getTourgradeMonthAvailability(sku, year, month, tourgrades)
      .then(
        (result) => {
          setIsLoaded(true);

          setAvailability(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sku, month, year, tourgradesJSON])

  if (error) {
    return <div>Error: {error.message}</div>;
  } else {

    let includeDates = availability.map( _ => new Date(_));

    return (
      <>
      <div style={{ position: 'relative' }}>
        <DatePicker
          selected={value}
          onChange={onChange}
          onMonthChange={_ => setMonth(_.getMonth() + 1) || setYear(_.getFullYear())}
          includeDates={includeDates}
          disabledKeyboardNavigation
          forceShowMonthNavigation
          inline
          />
          {!isLoaded && <div style={{ position: 'absolute', padding: 100, top: 0, left: 0, right: 0, bottom: 0, background: 'rgba(255,255,255,0.6)'}}>
            <Loader
              type="Puff"
              color="#FFB99B"
              height={50}
              width={50}
              />
          </div>}

      </div>
      </>
    );
  }
}