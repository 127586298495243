import React, { useState, useEffect } from 'react'

import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { format } from 'date-fns'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';

import ProductDatePicker from './ProductDatePicker';
import { getTourgradeAvailability } from '../api';
import Loader from 'react-loader-spinner';
import BookingHotelPicker from './BookingHotelPicker';
import { getTourGradeCode } from '../util';

const Heading = ({ children, step, hideStep }) => <>
  { step && step > 1 && <hr style={{ marginBottom: '3rem', marginTop: '2rem' }} />}
  <h4>
    {!hideStep && <small style={{ display: 'block', fontSize: '0.8rem' }}>Step {step}</small>}
    {children}
  </h4>
</>;

export default function BookingForm({ orderId, sku, tourGrade, travellers, createBooking, product, debug }) {
  const { register, handleSubmit, watch, errors, control } = useForm();
  const [tourgradeAvailability, setTourgradeAvailability] = useState();
  const [isLoading, setIsLoading] = useState(false);

  // Tourgrade availability
  const startDate = watch('start_date');
  useEffect(_ => {

    setTourgradeAvailability(false);

    if (!sku || !startDate) {
      return;
    }

    _getTourgradeAvailability();

    async function _getTourgradeAvailability() {
      setIsLoading(true);

      try {
        const result = await getTourgradeAvailability(sku, startDate, travellers);
        setTourgradeAvailability(result.data.filter(_ => _.available).map(_ => _.gradeCode));

      } catch (error) {
        // TODO
        console.error(error);

      }

      setIsLoading(false);

    }

  }, [sku, startDate, travellers, setTourgradeAvailability]);

  const ref = [orderId, sku, tourGrade, Date.now()].join('-');

  // Tourgrade
  const availableTourgrades = product.tourGradesAvailable && product.tourGrades && product.tourGrades.filter(_ => getTourGradeCode(_) === tourGrade);
  const tourgradeInfo = availableTourgrades && availableTourgrades[0];

  const bookableTourgrades = availableTourgrades && tourgradeAvailability && availableTourgrades.filter(_ => tourgradeAvailability.includes(_.gradeCode))
  const selectedTourgrade = watch('tourgrade', tourGrade);
  const selectedTourgradeInfo = bookableTourgrades && selectedTourgrade && bookableTourgrades.filter(_ => _.gradeCode === selectedTourgrade)[0];

  // Age Bands
  const ageBandSortorder = [1, 5, 2, 3, 4];

  // [ bandId, bandId, bandId, ... ]
  const travellerArr = travellers.map((num, i) => Array(num).fill(i))
    .filter(_ => _.length)
    .flat()
    .sort((a, b) => ageBandSortorder.indexOf(a) - ageBandSortorder.indexOf(b));

  const ageBands = {};
  product.ageBands && product.ageBands.forEach(ageBand => ageBands[ageBand.bandId] = ageBand)

  const getBookingRequest = _ => ({
    "currencyCode": "AUD",
    "partnerDetail": {
      "distributorRef": ref
    },
    "booker": {
      "email": watch('email'),
      "homePhone": watch('tel_0').replace(new RegExp(/[^0-9\-+()]/, 'g'), ''),
      "firstname": watch('first_name_0'),
      "surname": watch('last_name_0'),
      "title": watch('title_0', 'Mr'), // Not mandatory - take it out?
      "cellPhoneCountryCode": undefined, // TODO
      "cellPhone": undefined // TODO
    },
    "items": [
      {
        "partnerItemDetail": {
          "distributorItemRef": ref
        },
        "hotelId": watch('hotel'),
        "pickupPoint": watch('pickupPoint'),
        "travelDate": startDate ? format(startDate, 'yyy-MM-dd') : undefined,
        "productCode": sku,
        "tourGradeCode": selectedTourgrade,
        "languageOptionCode": watch('language_option_code'),
        "bookingQuestionAnswers": product.bookingQuestions ? product.bookingQuestions.map(_ => ({
          'questionId': _.questionId,
          'answer': watch('bq_' + _.questionId)
        })) : undefined,
        "specialRequirements": watch('special_requirements'),
        "travellers": travellerArr.map((bandId, i) => ({
          "bandId": bandId,
          "firstname": watch('first_name_' + i),
          "surname": watch('last_name_' + i),
          "title": watch('title_' + i, 'Mr'), // Not mandatory - take it out?
          "leadTraveller": (i === 0)
        }))
      }
    ]
  });

  const onSubmit = data => createBooking(getBookingRequest());
  let step = 1;

  return (
    <Container className="mb-4">
      <Row>
        <Col>

          <h1 className="mb-4">Book your experience</h1>

          <Form onSubmit={handleSubmit(onSubmit)}>

            <Heading step={step++}>Select a Date</Heading>

            <Controller
              as={ProductDatePicker}
              control={control}
              rules={{ required: "Please select your travel date" }}
              name="start_date"
              className="input"
              placeholderText="Select date"
              isInvalid={errors.start_date}
              sku={sku}
              tourgrades={availableTourgrades && availableTourgrades.map(_ => _.gradeCode)}
            />

            {startDate && <small>Your selected date: <span style={{
              textDecoration: 'underline',
              textDecorationStyle: 'dotted'
            }}>{format(startDate, 'dd-MM-yyyy')}</span></small>}

            <ErrorMessage render={({ message }) => <div class='invalid-feedback' style={{ display: 'block' }}>{message}</div>} name="start_date" errors={errors} />

            {isLoading && <Heading step={step++} hideStep><Loader
              type="Puff"
              color="#FFB99B"
              height={30}
              width={30}
              style={{ display: 'inline', marginRight: '10px' }}
            />Checking availability on {format(startDate, 'dd-MM-yyyy')}...</Heading>}

            {!isLoading && startDate && !bookableTourgrades && availableTourgrades && <>
              <Heading step={step++}>Tour Options</Heading>
              <p>No tour options available on {format(startDate, 'dd-MM-yyyy')}.</p>
            </>}

            {bookableTourgrades && <>

              <Heading step={step++}>Tour Options</Heading>

              <Form.Group controlId="language_option_code">
                <Form.Label hidden>Select Tour Options:</Form.Label>

                <Controller
                  control={control}
                  name="tourgrade"
                  defaultValue={bookableTourgrades[0].gradeCode}
                  render={({ onChange, onBlur, value, name }) => (
                    <>
                      {bookableTourgrades.map(_ => <Button
                        style={{ marginRight: 5, marginBottom: 5 }}
                        onBlur={onBlur}
                        onClick={e => onChange(_.gradeCode)}
                        key={_.gradeCode}
                        variant={value === _.gradeCode ? "primary" : "outline-primary"}
                      >{_.gradeTitle}
                      </Button>
                      )}
                    </>
                  )}
                />

                <ErrorMessage className="invalid-feedback" name="tourgrade" as="div" errors={errors} />
              </Form.Group>

              { selectedTourgradeInfo && <>
                <Table >
                  <tbody>
                    { selectedTourgradeInfo.gradeTitle &&
                    <tr>
                      <th>Selected Tour Option</th>
                      <td>{selectedTourgradeInfo.gradeTitle}</td>
                    </tr>
                    }
                    { selectedTourgradeInfo.gradeDescription &&
                    <tr>
                    <th>Description</th>
                    <td dangerouslySetInnerHTML={{ __html: selectedTourgradeInfo.gradeDescription }} />
                    </tr>
                    }
                    { selectedTourgradeInfo.gradeDepartureTime &&
                    <tr>
                    <th>Departure Time</th>
                    <td>{selectedTourgradeInfo.gradeDepartureTime}</td>
                    </tr>
                    }

                  </tbody>
                </Table>

              </>}

              {tourgradeInfo && tourgradeInfo.langServices && <>

                <Form.Group controlId="language_option_code">
                  <Form.Label>Select Language:</Form.Label>
                  <Form.Control
                    as="select"
                    name='language_option_code'
                    ref={register({
                      required: 'Please select a language option'
                    })}
                    isInvalid={errors.language_option_code}
                  >
                    {Object.entries(tourgradeInfo.langServices).map(([k, v]) => <option key={k} value={k}>{v}</option>)}
                  </Form.Control>

                  <ErrorMessage className="invalid-feedback" name="language_option_code" as="div" errors={errors} />
                </Form.Group>

              </>}
            </>}

            {!isLoading && startDate && product.hotelPickup && <>
              <Heading step={step++}>Hotel Pickup</Heading>

              <BookingHotelPicker
                register={register}
                watch={watch}
                errors={errors}
                sku={sku}
                debug={debug}
              />


            </>}
            {startDate && !isLoading && <>

              <Heading step={step++}>Guest Details</Heading>
              <p>Information we need to confirm your tour or activity.</p>

              {travellerArr.map((bandId, i) =>
                <React.Fragment key={'traveller-' + i}>
                  {i === 0 ? <h5>Main Guest ({ageBands[bandId].description})</h5> : <h5>Traveler {i + 1} ({ageBands[bandId].description})</h5>}
                  <Card className="mb-4">
                    <Card.Body>
                      { /* Lead Traveler */}
                      {i === 0 && <>
                        <Form.Row>
                          <Col>
                            <Form.Group controlId="form_email">
                              <Form.Label>Your Email address</Form.Label>
                              <Form.Control
                                type="email"
                                name="email"
                                placeholder="Enter email"
                                ref={register({ required: "Please enter your email address" })}
                                isInvalid={errors.email} />

                              <ErrorMessage className="invalid-feedback" name="email" as="div" errors={errors} />

                              <Form.Text className="text-muted">
                                You will receive your booking details on this email address.
                                </Form.Text>

                            </Form.Group>
                          </Col>
                        </Form.Row>
                      </>}
                      <Form.Row>
                        <Col xs={2}>
                          <Form.Group controlId={"form_title_" + i}>
                            <Form.Label>Title</Form.Label>
                            <Form.Control as="select" name={"title_" + i} ref={register({ required: true })}>
                              <option value="Mr">Mr</option>
                              <option value="Mrs">Mrs</option>
                              <option value="Miss">Miss</option>
                              <option value="Dr">Dr</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col xs={5}>
                          <Form.Group controlId={"form_first_name_" + i}>
                            <Form.Label>First Name</Form.Label>

                            <Form.Control
                              type="text"
                              name={"first_name_" + i}
                              placeholder="First Name"
                              ref={register({ required: "Please enter the first name" })}
                              isInvalid={errors["first_name_" + i]}
                            />

                            <ErrorMessage className="invalid-feedback" name={"first_name_" + i} as="div" errors={errors} />

                          </Form.Group>
                        </Col>
                        <Col xs={5}>
                          <Form.Group controlId={"form_last_name_" + i}>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              type="text"
                              name={"last_name_" + i}
                              placeholder="Last Name"
                              ref={register({ required: "Please enter a last name" })}
                              isInvalid={errors["last_name_" + i]}
                            />
                            <ErrorMessage className="invalid-feedback" name={"last_name_" + i} as="div" errors={errors} />
                          </Form.Group>
                        </Col>
                        {
                          i === 0 && <Col>
                            <Form.Group controlId={"form_tel_" + i}>
                              <Form.Label>Phone Number</Form.Label>
                              <Form.Control
                                type="tel"
                                name={"tel_" + i}
                                placeholder="Phone Number"
                                ref={register({ required: "Please enter your phone number" })}
                                isInvalid={errors["tel_" + i]}
                              />

                              <ErrorMessage className="invalid-feedback" name={"tel_" + i} as="div" errors={errors} />
                            </Form.Group>
                          </Col>
                        }

                      </Form.Row>
                      {/* Booking Questions */}
                      {i === 0 && product.bookingQuestions && product.bookingQuestions.map(bookingQuestion => <>
                        <Form.Row key={'bq-' + bookingQuestion.questionId}>
                          <Col>
                            <Form.Group controlId={"bq_" + bookingQuestion.questionId}>
                              <Form.Label>
                                {bookingQuestion.title}<br />
                                <small>{bookingQuestion.subTitle}</small>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name={"bq_" + bookingQuestion.questionId}
                                ref={register({ required: bookingQuestion.message })}
                                isInvalid={errors["bq_" + bookingQuestion.questionId]}
                              />

                              <ErrorMessage className="invalid-feedback" name={"bq_" + bookingQuestion.questionId} as="div" errors={errors} />
                            </Form.Group>
                          </Col>
                        </Form.Row>
                      </>)}
                      {/* Special Requirements */}
                      {i === 0 && <Form.Row>
                          <Col>
                            <Form.Group controlId={"special_requirements"}>
                              <Form.Label>
                                Any Special Requirements?<br />
                                <small>For example, if you require wheelchair assistance</small>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="special_requirements"
                                ref={register()}
                                isInvalid={errors["special_requirements"]}
                              />

                              <ErrorMessage className="invalid-feedback" name={"special_requirements"} as="div" errors={errors} />
                            </Form.Group>
                          </Col>
                        </Form.Row>}
                    </Card.Body>
                  </Card>
                </React.Fragment>
              )}

              <Button variant="primary" type="submit">
                Create Booking
                </Button>
            </>}

          </Form>
        </Col>
      </Row>
      { debug && <Row>
        <Col>
          <pre>{JSON.stringify(getBookingRequest, null, "\t")}</pre>
        </Col>
      </Row>}
    </Container>
  );
}
