import React, {useEffect, useState} from 'react';
import { getHotels } from '../api';
import Form from 'react-bootstrap/Form';
import { ErrorMessage } from '@hookform/error-message';
import Loader from 'react-loader-spinner';


export default function BookingHotelPicker({ sku, debug, register, watch, errors }){
    const [hotels, setHotels] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        getData();

        async function getData() {
            setHotels([]);

            if ( !sku ) {
                return;
            }
            setIsLoaded(false);

            const result = await getHotels(sku);
            setIsLoaded(true);
            setHotels(result.data);

        }
    }, [sku])

    return isLoaded ? <div>
        <Form.Control as="select" name='hotel' ref={register({ required: "Please select a pickup point" })} isInvalid={errors["hotel"]}>
            <option value=""></option>
            {hotels.map(_ => <option key={_.id} value={_.id}>{_.name}</option>)}
        </Form.Control>
        <ErrorMessage className="invalid-feedback" name={"hotel"} as="div" errors={errors} />

        { (hotels.length === 0 || watch('hotel') === 'notListed') && <Form.Group controlId={"pickupPoint"}>
                          <Form.Label hidden>Enter your pickup point</Form.Label>

                          <Form.Control
                            type="text"
                            name="pickupPoint"
                            placeholder="Where do you want to be picked up?"
                            ref={register({required: "Please enter where you would like to be picked up."})}
                            isInvalid={errors["pickupPoint"]}
                            />

                          <ErrorMessage className="invalid-feedback" name="pickupPoint" as="div" errors={errors} />

                        </Form.Group>}
        {debug && <pre>{JSON.stringify(hotels, false, "\t")}</pre>}
    </div> : <Loader
              type="Puff"
              color="#FFB99B"
              height={30}
              width={30}
              style={{ display: 'inline', marginRight: '10px' }}
              />;
}