import React from 'react';
import OrderBookingPage from './components/OrderBookingPage';
import DevOrderBookingPage from './components/DevOrderBookingPage';
import './App.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

// eslint-disable-next-line no-unused-vars
import ProductBookingPage from './components/ProductBookingPage';

// https://gist.github.com/miohtama/1570295/289d5a82e65663c9b515c88186a268c6dd1fddb7
function parseHashBangArgs(aURL) {
	aURL = aURL || window.location.href;

	var vars = {};
	var hashes = aURL.slice(aURL.indexOf('#') + 1).split('&');
    for(var i = 0; i < hashes.length; i++) {
       var hash = hashes[i].split('=');

       if(hash.length > 1) {
    	   vars[hash[0]] = hash[1];
       } else {
     	  vars[hash[0]] = null;
       }
    }
    return vars;
}

const { k: voucherKey, s: voucherSecret, dev } = parseHashBangArgs();

function App() {
  return (
    <div className="App">
      {dev ?
      (dev === 'products' ? <ProductBookingPage /> : <DevOrderBookingPage />)
      :
      <OrderBookingPage
        voucherKey={voucherKey}
        voucherSecret={voucherSecret}
        />
      }
    </div>
  );
}

export default App;
