import React from 'react';
import { Card, Button, Badge, Row, Col } from 'react-bootstrap';
import { BsGeoAlt } from 'react-icons/bs';

export default function ProductSelector({ products, setTour }){

    return <>
        <h1 className='text-center mb-4' style={{ margin: '0 auto'}}>Please select the experience you would like to book:</h1>
        <Row className='mb-4'>
                {products && products.map(({sku, product, travellers, tourGrade, booking }, i) => <React.Fragment key={'tour-' + sku}>
                    <Col md={6}>

                        <Card style={{ cursor: 'pointer' }} className='h-100' onClick={_ => setTour(i)}>
                            <Card.Img variant="top" src={product.thumbnailHiResURL}>
                            </Card.Img>
                            <Card.Body>
                                <Card.Title>{product.title}</Card.Title>
                                <Card.Text>
                                <p><BsGeoAlt /> {product.location} { booking && <Badge variant="success" className="float-right">Booked</Badge> }</p>
                                <p dangerouslySetInnerHTML={{ __html: product.shortDescription }} />

                                <h5>Available Tickets</h5>
                                <AgeBands product={product} travellers={travellers} />

                                </Card.Text>
                            </Card.Body>
                            <Card.Footer>
                                { booking ?
                                    <Button variant="secondary">View Experience Information</Button>
                                    :
                                    <Button variant="primary">Book This Experience</Button>
                                }

                            </Card.Footer>
                        </Card>
                    </Col>
                </React.Fragment>)}
        </Row>
    </>
}

function AgeBands({ product, travellers }) {
    // Age Bands
    const ageBandSortorder = [1, 5, 2, 3, 4];

    const ageBands = {};
    product.ageBands && product.ageBands.forEach(ageBand => ageBands[ageBand.bandId] = ageBand)

    return <ul>
    {
        ageBandSortorder.map( (bandId) => <React.Fragment key={'band-' + bandId}>
            { travellers[bandId] ? <li>{travellers[bandId]}x {ageBands[bandId].description}</li> : <></>}
        </React.Fragment>)
    }
    </ul>;
}